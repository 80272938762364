
  .app-debug-section-ably .app-debug-ably-client-session {
    text-align: center;
  }
.app-debug-section-ably .app-debug-inspect-grid {
    display: flex;
    flex-flow: column
  }
.app-debug-section-ably .app-debug-inspect-grid .app-debug-inspect-grid-body {
      display: inline-grid;
      grid-template-columns: 1fr auto 1fr auto;
      grid-gap: 0.2em 0.6em;
      align-items: center
    }
.app-debug-section-ably .app-debug-inspect-grid .app-debug-inspect-grid-body > span {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 0.1em;
      }
.app-debug-section-ably .app-debug-inspect-grid .app-debug-inspect-grid-body .app-debug-inspect-grid-row {
        grid-column: span 4;
      }
.app-debug-section-ably .app-debug-ably-client-buttons-wrapper {
    display: flex;
    justify-content: center;
    margin: 0.5em 0;
  }
